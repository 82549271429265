import { connect } from 'react-redux'

import Zip from './zip'

import { nextStep, submitZip } from '../../../reducers/captive'

export default connect((state) => state, {
  nextStep,
  submitZip,
})(Zip)
